import { Center, Image } from "@chakra-ui/react";
import { IMAGE_ALLOWED_TYPE_TEXT } from "@config/constant";
import { Icon } from "@iconify/react";

const AssetThumbnailRender = ({ file, isUploaded = true }: { file: any; isUploaded?: boolean }) => {
  const type = file.type;
  const isImage = IMAGE_ALLOWED_TYPE_TEXT.some(itm => type?.includes(itm));
  return isImage ? (
    <Image
      style={{ filter: isUploaded ? "opacity(1)" : "opacity(0.5)" }}
      src={URL.createObjectURL(file)}
      alt="uploaded"
      bg={"gray.50"}
      h={12}
      w={12}
      objectFit={"cover"}
      p={1}
      borderRadius={"md"}
      overflow={"hidden"}
    />
  ) : (
    <Center h={"full"}>
      <Icon icon={"basil:document-outline"} fontSize={24} />
    </Center>
  );
};

export default AssetThumbnailRender;
