import { Box, Button, Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { caseHistoryConvertToNowFromScheduled, clickOnForceDispatchButton, clickOnForceDispatchModalClose, clickOnForceDispatchModalSubmit } from "@config/event";
import { useCancelOrder, useForceDispatch } from "@hooks/api/useCaseApi";
import { useRedStore } from "@store/store";
import { showSuccessToast } from "@utils";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { queryClient } from "scripts/reactQuery";

export default function ForceDispatch({ isDisabled }: { isDisabled?: boolean }) {
  const { changeNow, ...rest } = useForceDispatch();
  const navigate = useNavigate();

  const orderStatus = useRedStore(state => state.order.orderStatus);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: orderId } = useParams();

  const handleModalOpen = () => {
    clickOnForceDispatchButton({
      orderId: orderId,
    });
    onOpen();
  };

  const handleModalClose = () => {
    clickOnForceDispatchModalClose({
      orderId: orderId,
    });
    onClose();
  };

  const handleCancelClick = () => {
    caseHistoryConvertToNowFromScheduled({
      orderId: orderId,
    });
    changeNow(() => {
      showSuccessToast("Order Convert from Scheduled to Unfulfilled");
      clickOnForceDispatchModalSubmit({
        orderId: orderId,
      });
      onClose();
      queryClient.invalidateQueries([`case-id-${orderId}`]);
      navigate(`/case/${orderId}/ready-to-dispatch`);
    });
  };

  return (
    <Box w={"full"}>
      {/* <Button
        onClick={onOpen}
        borderRadius={"md"}
        variant={"solid"}
        size={"xs"}
        fontWeight={"semibold"}
        my={2}
        colorScheme={"blackAlpha"}
        bg={"blackAlpha.800"}
        isDisabled={orderStatus == "cancelled" || isDisabled}
        _disabled={{
          bg: "blackAlpha.300",
          opacity: 0.5,
        }}
      >
        Dispatch Now
      </Button> */}
      <Button
        onClick={handleModalOpen}
        borderRadius={"md"}
        variant={"solid"}
        fontSize={"sm"}
        size={"sm"}
        w="full"
        fontWeight={"semibold"}
        my={2}
        isDisabled={orderStatus == "cancelled" || isDisabled}
        _disabled={{
          bg: "blackAlpha.300",
          opacity: 0.5,
        }}
      >
        Dispatch Now
      </Button>
      <Modal isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent mx={5}>
          <ModalCloseButton />
          <ModalHeader>Confirm</ModalHeader>
          <Divider />
          <ModalBody>
            <Box minH={"10vh"} overflowY={"scroll"}>
              <Text fontWeight={"normal"} fontSize={"sm"}>
                Do you want to Convert this case from the <span style={{ fontWeight: 800, color: "black" }}>SCHEDULED </span>to<span style={{ fontWeight: 800, color: "black" }}> NOW</span>?
              </Text>
            </Box>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button onClick={handleCancelClick} borderRadius={"full"} px={6} isLoading={rest?.isLoading} size={"sm"} colorScheme="purple" variant={"solid"} fontWeight={"semibold"}>
              Convert
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
