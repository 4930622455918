import { Box, Button, Center, Divider, Flex, Heading, Highlight, Image, Stack, Text, Wrap, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { Icon } from "@iconify/react";

import { useState } from "react";

import { IDocument } from "@config/types";
import { convertEpochToDate, objToQuery, otherDocumentFilter, showErrorToast, showSuccessToast } from "@utils";
import UploadDocumentButton from "@components/molecules/UploadDocumentButton";
import { useRedStore } from "@store/store";
import DocumentCard from "@components/molecules/DocumentCard";
import { useAssetRemove } from "@hooks/api/useCaseApi";
import { queryClient } from "scripts/reactQuery";
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from "@chakra-ui/react";
import AssetRender from "@components/molecules/AssetRender";
import { MAX_DOCUMENT_COUNT } from "@config/constant";
import { useNavigate } from "react-router-dom";

export default function NotAllowToDispatch({ message, query = "" }: { message: string; query?: string }) {
  const darkSlate = useColorModeValue("white", "slate.800");
  const order = useRedStore(state => state.order);
  const buttonColorValue = useColorModeValue("#151f21", "gray.900");
  const navigate = useNavigate();

  // const params = {
  //   onSuccessNavigate: `/case-history/${order?.orderId}/case-overview`,
  // };

  // const handleOnPayClick = () => {
  //   navigate(`/case/${order?.orderId}/ready-to-dispatch?${objToQuery(params)}`);
  // };

  return (
    <Wrap w={"full"} bg={darkSlate} px={4} py={4} mt={6} spacing={10} borderRadius={"md"}>
      {" "}
      <Center w={"full"} flexDirection={"column"}>
        <Image src="/assets/ambulances/no-car.png" w={20} my={5} />
        {/* <Text my={5} mt={2} textAlign={"center"} fontSize={"sm"}>
          Ambulance assignment failed or is pending. Please assign a vehicle.
        </Text> */}

        <Text my={5} mt={2} textAlign={"center"} fontSize={"sm"}>
          <Highlight query={query} styles={{ rounded: "full", fontWeight: 800 }}>
            {message}
          </Highlight>
        </Text>

        {/* <Button
          px={8}
          bg={buttonColorValue}
          color={"white"}
          rounded={"md"}
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "lg",
          }}
          onClick={handleOnPayClick}
          w={"full"}
          // isLoading={smsStatus.isLoading}
          // isDisabled={phoneNo?.length !== 10}
        >
          Assign Vehicle
        </Button> */}
      </Center>
    </Wrap>
  );
}
