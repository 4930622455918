import { Badge, Box, Center, CircularProgress, CircularProgressLabel, Divider, Flex, IconButton, Progress, Spinner, Stack, Text } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { UploadProps } from "rc-upload";

import { Line } from "rc-progress";
import Upload from "rc-upload";
import { useState } from "react";
import { useFileUpload } from "@hooks/api/useAppApi";
import { IFileType } from "@config/types";
import { formatFileSize, logger, showErrorToast } from "@utils";

import axios from "axios";
import { ALLOWED_FILE_TYPES, MAX_FILE_SIZE } from "@config/constant";
import AssetThumbnailRender from "@components/molecules/AssetThumbnailRender";
import { Control, useForm } from "react-hook-form";

interface IUploadAssetRsp {
  orderId: string;
  tag: string;
  url: string;
}

interface IUploadAsset {
  onSuccess?: (payload: IUploadAssetRsp) => void;
  orderId: string;
  tag: string;
  isDisabled?: boolean;
}

const allowedFileTypes = ALLOWED_FILE_TYPES;

export const UploadAsset = ({ onSuccess, orderId, tag, isDisabled }: IUploadAsset) => {
  const { getUploadLink, ...rest } = useFileUpload();
  const [percentage, setPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<IFileType | null>(null);
  const [uploadError, setUploadError] = useState("");

  const handleBeforeUpload = (_file: any) => {
    try {
      const file: IFileType = _file;
      console.log(file.type, allowedFileTypes);
      if (file.size > MAX_FILE_SIZE) {
        throw Error(`The selected file size is ${formatFileSize(file.size)} . File size cannot be more than ${formatFileSize(MAX_FILE_SIZE)}.
      `);
      }

      if (!allowedFileTypes?.some(key => file?.type?.includes(key))) {
        throw Error(`Only ${allowedFileTypes?.join(", ")} type are allowed.
      `);
      }

      setSelectedFile(file);
      setIsUploading(true);
    } catch (err: any) {
      logger("FILE-UPLOAD", err, "warn");
      err && showErrorToast(err?.toString());
    }
  };

  const handleCustomRequest = async () => {
    try {
      // Generating URL to upload file
      if (!selectedFile) {
        // showErrorToast("Please select file again.");
        return;
      }

      const { data } = await getUploadLink(selectedFile, orderId, tag);

      const patchUrl = data?.putUrl[0];

      if (!patchUrl) {
        showErrorToast("Unable to find Upload URL.");
        return;
      }

      await axios.put(patchUrl, selectedFile, {
        headers: {
          "Content-Type": selectedFile.type,
        },
        onUploadProgress: progressEvent => {
          const progress = progressEvent?.progress ? parseInt((progressEvent?.progress * 100)?.toString()) : 0;
          if (progress >= 100) {
            setIsUploading(false);
            setUploadError("");
            onSuccess && onSuccess({ orderId: orderId, tag: tag, url: data?.getUrl });
          }
          setPercentage(progress);
        },
      });
    } catch (err) {
      showErrorToast("Unable to generate file Upload link.", 5000);
      setUploadError("Failed to upload this image.");
      setIsUploading(false);
      console.log("error: ", err);
    }
  };

  const props: UploadProps = {
    accept: ".png, .pdf, .jpg, .jpeg",
    customRequest: handleCustomRequest,
    beforeUpload: handleBeforeUpload,
    onError(err) {
      console.log("onError", err);
      setIsUploading(false);
    },
  };

  const removeImage = () => {
    setUploadError("");
    setPercentage(0);
    setIsUploading(false);
    setSelectedFile(null);
    onSuccess && onSuccess({ orderId, tag, url: "" });
  };

  return (
    <Box w={"full"}>
      <Stack gap={2}>
        {selectedFile?.name && (
          <Box key={selectedFile?.uid} w={"full"} shadow={"sm"} p={2} borderWidth={1} borderRadius={"md"} mb={2}>
            <Flex w={"full"} justify={"space-between"} align={"start"}>
              <Flex align={"start"}>
                {selectedFile && (
                  <Box h={12} w={12} minW={12} borderRadius={"md"} borderWidth={1} borderColor={"gray.300"} overflow={"hidden"} mr={2}>
                    {rest?.isLoading ? (
                      <Center bg={"white"} shadow={"sm"} borderWidth={1} borderColor={"gray.300"} borderStyle={"solid"} p={3} borderRadius={"md"} minH={12} minW={12}>
                        <Spinner size={"md"} thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" />
                      </Center>
                    ) : (
                      <AssetThumbnailRender file={selectedFile} isUploaded={!uploadError} />
                    )}
                  </Box>
                )}
                <Stack align={"start"} gap={1}>
                  <Text fontSize={"sm"} fontWeight={"semibold"} maxW={"50vw"}>
                    {selectedFile.name}
                  </Text>
                  <Badge colorScheme="orange">{`${formatFileSize(selectedFile.size)}`}</Badge>
                </Stack>
              </Flex>
              <Flex h={10} w={10} align={"center"} justify={"center"} borderRadius={"md"} minW={10} ml={2}>
                {/* <Text fontSize={"xs"} fontWeight={"semibold"}>
                  {isUploading ? (
                    ` ${percentage}% `
                  ) : (
                    <IconButton bg={"gray.100"} p={0} aria-label="Search database" icon={<Icon color="black" icon={"solar:trash-bin-trash-bold-duotone"} onClick={removeImage} fontSize={20} />} />
                  )}
                </Text> */}

                <Text fontSize={"xs"} fontWeight={"semibold"}>
                  {isUploading ? (
                    <CircularProgress value={percentage} color="green.400">
                      <CircularProgressLabel fontSize={"sm"}>{percentage}%</CircularProgressLabel>
                    </CircularProgress>
                  ) : (
                    <IconButton bg={"gray.100"} p={0} aria-label="Search database" icon={<Icon color="black" icon={"solar:trash-bin-trash-bold-duotone"} onClick={removeImage} fontSize={20} />} />
                  )}
                </Text>
              </Flex>
            </Flex>
            {/* {isUploading && (
              <Box>
                <Divider my={2} />
                <Flex>
                  <Progress value={percentage} size="xs" colorScheme="pink" hasStripe />
                  <Line percent={percentage} strokeWidth={1} trailWidth={5} trailColor="#FFF" strokeColor={isUploading ? "#41C3D2" : "#92ed14"} />
                  <CircularProgress value={percentage} color="green.400">
                    <CircularProgressLabel fontSize={"sm"}>{percentage}%</CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              </Box>
            )} */}
          </Box>
        )}
      </Stack>
      <>
        <Upload {...props} disabled={isDisabled}>
          <>
            {!selectedFile && (
              <Flex
                opacity={isDisabled ? 0.4 : 1}
                w={"full"}
                h={20}
                borderWidth={2}
                borderRadius={"md"}
                borderStyle={"dotted"}
                borderColor={"gray.300"}
                bg={"gray.100"}
                justify={"center"}
                align={"center"}
                fontSize={"sm"}
                gap={1}
              >
                <Icon icon={"pepicons-pencil:file"} />
                Select File
              </Flex>
            )}
          </>
        </Upload>
        {uploadError && (
          <Text fontSize={"xs"} color={"red"}>
            {uploadError}
          </Text>
        )}
        <Box mt={2}>
          <ol>
            <li>
              {" "}
              <Text fontSize={"xs"} fontWeight={"normal"}>
                1. Only .pdf, .png, .jpg file type allowed
              </Text>
            </li>
            <li>
              <Text fontSize={"xs"} fontWeight={"normal"}>
                2. Max file size is <span style={{ fontWeight: 800 }}>{formatFileSize(MAX_FILE_SIZE)}</span>
              </Text>
            </li>
          </ol>
        </Box>
      </>
    </Box>
  );
};
