import {
  Box,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Tag,
  useColorModeValue,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormHelperText,
  Input,
  useDisclosure,
  Button,
  Text,
  Switch,
  InputGroup,
  InputLeftAddon,
  IconButton,
} from "@chakra-ui/react";

import TextRenderWithIcon from "@components/atom/TextRenderWithIcon";
import CustomMaskedInput from "@components/molecules/CustomMaskedInput";
import FormMaskedInput from "@components/molecules/FormMaskedInput";
import { InputFieldOptions } from "@config/constant";
import { clickOnContactEditButton, clickOnContactSaveButton, closePatientDetailsModal, toggleContactIsPatient } from "@config/event";
import { useVoiceCall } from "@hooks/api/useDaasApi";
import { useOrder } from "@hooks/api/useSave";
import { Icon } from "@iconify/react";
import useOrderAction from "@store/action-hook/useOrderAction";

import { useRedStore } from "@store/store";
import { maskString } from "@utils";
import { getProperty } from "dot-prop";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";

const PatientSection = () => {
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const extractValue = (key: string) => getProperty(localOrderChanges, key) || "";
  const darkSlate = useColorModeValue("white", "slate.800");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { makeVoiceCall, ...rest } = useVoiceCall();

  const requestorContact = extractValue("requestedBy.mobile");
  const patientContact = extractValue("requestedFor.mobile");

  const handleOnOpen = () => {
    clickOnContactEditButton({
      orderId: extractValue("orderId"),
    });
    onOpen();
  };

  const handleOnClose = () => {
    closePatientDetailsModal({
      orderId: extractValue("orderId"),
    });
    onClose();
  };

  const handleSubmit = () => {
    clickOnContactSaveButton({
      orderId: extractValue("orderId"),
    });
    handleOnClose();
  };

  return (
    <>
      <Stack w={"full"} bg={darkSlate} py={4} mt={6} spacing={10} borderRadius={"md"}>
        <Box w={"full"} mb={"-6"}>
          <Flex justify={"space-between"} align={"center"} px={4}>
            <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
              Contact Details
            </Heading>
            {/* <Tag onClick={onOpen} size={"sm"} h={6} variant={"solid"} colorScheme="primary" px={4} borderRadius={"full"} fontWeight={"semibold"}>
              Edit
            </Tag> */}
            <Button
              onClick={handleOnOpen}
              variant={"ghost"}
              colorScheme="primary"
              size={"xs"}
              borderRadius={"full"}
              fontWeight={"semibold"}
              px={4}
              pr={0}
              mr={0}
              leftIcon={<Icon fontSize={16} icon="line-md:edit-twotone" />}
              _focus={{ borderWidth: 0 }}
            >
              Edit
            </Button>
          </Flex>
          <Divider my={2} />
        </Box>
        <SimpleGrid w={"full"} columns={1} gap={8} px={4}>
          <TextRenderWithIcon icon="mingcute:user-3-fill" title={"Caller Name"} description={extractValue("requestedBy.name")} />
          <Flex justify={"space-between"}>
            <TextRenderWithIcon icon={"bi:phone"} title={"Caller Contact Number"} description={maskString(extractValue("requestedBy.mobile"))} />
            <IconButton
              borderRadius={"full"}
              // bg={"blue.100"}
              borderWidth={1}
              variant={"outline"}
              size={"sm"}
              aria-label="Search database"
              isLoading={rest?.isLoading}
              isDisabled={!requestorContact}
              onClick={() => makeVoiceCall(requestorContact || "")}
              icon={<Icon icon={"solar:phone-calling-bold-duotone"} fontSize={20} />}
            />
          </Flex>
          <TextRenderWithIcon icon={"mdi:patient-outline"} title={"Patient Name"} description={extractValue("requestedFor.name")} />
          <Flex justify={"space-between"}>
            <TextRenderWithIcon icon="ic:round-local-phone" title={"Patient Contact Number"} description={maskString(extractValue("requestedFor.mobile"))} />
            <IconButton
              borderRadius={"full"}
              // bg={"blue.100"}
              borderWidth={1}
              variant={"outline"}
              size={"sm"}
              aria-label="Search database"
              isLoading={rest?.isLoading}
              isDisabled={!patientContact}
              onClick={() => makeVoiceCall(patientContact || "")}
              icon={<Icon icon={"solar:phone-calling-bold-duotone"} fontSize={20} />}
            />
          </Flex>
        </SimpleGrid>
      </Stack>
      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <ModalOverlay />
        <ModalContent mx={5} bg={"white"}>
          <ModalHeader>Patient Details</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <PatientDetailsSection onSuccess={() => handleSubmit()} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const PatientDetailsSection = ({ onSuccess }: { onSuccess?: any }) => {
  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const { updatePatientDetails, ...rest } = useOrder();
  const { __updateRequestedInfo } = useOrderAction();
  const [isRequesterPatient, setIsRequesterPatient] = useState(false);

  const porvider = useForm({
    defaultValues: {
      requestedBy_name: localOrderChanges?.requestedBy?.name,
      requestedBy_mobile: localOrderChanges?.requestedBy?.mobile,
      requestedFor_name: localOrderChanges?.requestedFor?.name,
      requestedFor_mobile: localOrderChanges?.requestedFor?.mobile,
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = porvider;
  const formValues: any = useWatch({ control });

  const isSame = formValues.requestedBy_name == formValues.requestedFor_name && formValues.requestedBy_mobile == formValues.requestedFor_mobile;

  const sameRequestedByAndRequestedFor = (e: any) => {
    const isChecked = e.target.checked;
    toggleContactIsPatient({
      orderId: localOrderChanges?.orderId,
      isPatient: isChecked,
    });
    setIsRequesterPatient(isChecked);
    if (isChecked) {
      setValue("requestedFor_name", formValues?.requestedBy_name, {
        shouldValidate: true,
      });
      setValue("requestedFor_mobile", formValues?.requestedBy_mobile, {
        shouldValidate: true,
      });
    } else {
      setValue("requestedFor_name", localOrderChanges?.requestedFor?.name, {
        shouldValidate: true,
      });
      setValue("requestedFor_mobile", localOrderChanges.requestedFor?.mobile, {
        shouldValidate: true,
      });
    }
  };

  const onSubmit = (value: any) => {
    console.log(value);
    event?.preventDefault();

    const reqFor = {
      name: value?.requestedFor_name,
      mobile: value?.requestedFor_mobile,
    };

    const reqBy = {
      name: value?.requestedBy_name,
      mobile: value?.requestedBy_mobile,
    };

    __updateRequestedInfo(reqFor, reqBy);
    updatePatientDetails(reqFor, reqBy);
    onSuccess && onSuccess();
  };

  return (
    <FormProvider {...porvider}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={5}>
          <FormControl size={"md"} isRequired>
            <FormLabel fontSize={"sm"}>Contact Person Name</FormLabel>
            <Input type="name" minLength={3} {...control.register("requestedBy_name", InputFieldOptions.name)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontSize={"sm"}>Contact Person Contact Number</FormLabel>
            <CustomMaskedInput defaultValue={formValues?.requestedBy_mobile} name="requestedBy_mobile" placeholder="phone number" leftAddonContent="+91" />
          </FormControl>
          <Flex justify={"space-between"} align={"center"}>
            <Text fontWeight={"semibold"}>Contact is Patient</Text>
            <Switch isChecked={isSame && isRequesterPatient} onChange={sameRequestedByAndRequestedFor} />
          </Flex>
          {
            <>
              {" "}
              <FormControl isRequired isDisabled={isSame && isRequesterPatient}>
                <FormLabel fontSize={"sm"}>Patient Name</FormLabel>
                <Input type="name" {...control.register("requestedFor_name", InputFieldOptions.name)} />
              </FormControl>
              <FormControl isRequired isDisabled={isSame && isRequesterPatient}>
                <FormLabel fontSize={"sm"}>Patient Contact number</FormLabel>
                <CustomMaskedInput defaultValue={formValues?.requestedFor_mobile} name="requestedFor_mobile" placeholder="phone number" leftAddonContent="+91" />
              </FormControl>{" "}
            </>
          }
          <Button mt={5} mb={4} colorScheme="teal" type="submit" isDisabled={!isValid} isLoading={rest?.isLoading}>
            Submit
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default PatientSection;
