import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useCancelOrder, useSendDocumentLink } from "@hooks/api/useCaseApi";
import { useRedStore } from "@store/store";
import { showSuccessToast } from "@utils";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { queryClient } from "scripts/reactQuery";
import { Icon } from "@iconify/react";
const MOBILE_NUMBER_REGEX = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/;

export default function DocumentUploadLink() {
  const orderData = useRedStore(state => state.order);
  const orderStatus = useRedStore(state => state.order.orderStatus);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { sendDocumentLink, ...rest } = useSendDocumentLink();
  const [mobileNumber, setMobileNumber] = useState(orderData?.requestedBy?.mobile || "");
  const [selectedNumberType, setSelectedNumberType] = useState("ATTENDEE");

  const handleNumberInput = (e: any) => {
    setMobileNumber(e);
  };

  const handleNumberTypeSelect = (option: string) => {
    setSelectedNumberType(option);
    switch (option) {
      case "ATTENDEE":
        setMobileNumber(orderData?.requestedBy?.mobile);
        break;
      case "PATIENT":
        setMobileNumber(orderData?.requestedFor?.mobile);
        break;
      default:
        setMobileNumber("");
        break;
    }
  };

  const handleCancelClick = () => {
    sendDocumentLink(mobileNumber, selectedNumberType, () => {
      showSuccessToast("Document upload link sent successfully 😃.");
      onClose();
    });
  };

  return (
    <Box>
      <Button
        px={4}
        size={"sm"}
        variant={"ghost"}
        rounded={"full"}
        colorScheme="purple"
        bg={"white"}
        onClick={onOpen}
        fontWeight={"semibold"}
        borderWidth={1}
        borderColor={"purple.200"}
        w={"full"}
        isLoading={rest.isLoading}
        isDisabled={orderStatus == "cancelled"}
        leftIcon={<Icon icon="basil:document-outline" fontSize={20} />}
      >
        Send Document Upload Link
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={5} bg={"white"}>
          <ModalCloseButton />
          <ModalHeader fontSize={"md"}>Send Document Upload Link</ModalHeader>
          <Divider />
          <ModalBody>
            <Box overflowY={"scroll"} bg={"whiteAlpha.200"} p={2} mx={-2} borderRadius={"md"}>
              <Center>
                <Stack spacing={4} justify={"space-between"} align={"center"} minH={"10vh"}>
                  <Center flexDir={"column"} textAlign={"center"} mb={6}>
                    <FormControl my={2} isRequired>
                      <FormLabel fontSize={"xs"} mb={2}>
                        Please select a mobile number, or type a custom number, and click the send button.
                      </FormLabel>
                      <InputGroup size={"sm"}>
                        <InputLeftAddon children="+91" />
                        <Input
                          value={mobileNumber}
                          onChange={e => handleNumberInput(e.target.value)}
                          bg={"white"}
                          type="tel"
                          placeholder="phone number"
                          maxLength={10}
                          isDisabled={selectedNumberType !== "OTHERS"}
                        />
                      </InputGroup>
                    </FormControl>
                    <Flex gap={2} mt={3}>
                      <Tag
                        colorScheme={selectedNumberType === "ATTENDEE" ? "primary" : "gray"}
                        borderRadius={"md"}
                        variant={selectedNumberType === "ATTENDEE" ? "solid" : "subtle"}
                        onClick={() => handleNumberTypeSelect("ATTENDEE")}
                      >
                        Attender
                      </Tag>
                      <Tag
                        colorScheme={selectedNumberType === "PATIENT" ? "primary" : "gray"}
                        borderRadius={"md"}
                        variant={selectedNumberType === "PATIENT" ? "solid" : "subtle"}
                        onClick={() => handleNumberTypeSelect("PATIENT")}
                      >
                        Patient
                      </Tag>
                      <Tag
                        colorScheme={selectedNumberType === "OTHERS" ? "primary" : "gray"}
                        borderRadius={"md"}
                        variant={selectedNumberType === "OTHERS" ? "solid" : "subtle"}
                        onClick={() => handleNumberTypeSelect("OTHERS")}
                      >
                        Others
                      </Tag>
                    </Flex>
                  </Center>
                  {/* <Button
                    px={8}
                    bg={buttonColorValue}
                    color={"white"}
                    rounded={"md"}
                    _hover={{
                      transform: "translateY(-2px)",
                      boxShadow: "lg",
                    }}
                    // onClick={handleSendPaymentLink}
                    // w={"full"}
                    // isLoading={smsStatus.isLoading}
                    // isDisabled={phoneNo?.length !== 10}
                  >
                    Send Link
                  </Button> */}
                </Stack>
              </Center>
            </Box>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button
              onClick={handleCancelClick}
              isDisabled={!MOBILE_NUMBER_REGEX?.test(mobileNumber)}
              borderRadius={"full"}
              px={6}
              isLoading={rest?.isLoading}
              colorScheme="primary"
              w={"full"}
              size={"sm"}
            >
              Send Document Upload Link
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
