import { Box, Button, Center, Divider, Flex, Heading, Stack, Text, Wrap, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { Icon } from "@iconify/react";

import { useState } from "react";

import { IDocument } from "@config/types";
import { bthDocumentFilter, convertEpochToDate, showErrorToast, showSuccessToast } from "@utils";
import UploadDocumentButton from "@components/molecules/UploadDocumentButton";
import { useRedStore } from "@store/store";
import DocumentCard from "@components/molecules/DocumentCard";
import { useAssetRemove } from "@hooks/api/useCaseApi";
import { queryClient } from "scripts/reactQuery";
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from "@chakra-ui/react";
import AssetRender from "@components/molecules/AssetRender";
import { MAX_DOCUMENT_COUNT } from "@config/constant";
import { randomUUID } from "crypto";
import { uniqueId } from "lodash";

export default function UploadBTHDocuments() {
  const darkSlate = useColorModeValue("white", "slate.800");
  const order = useRedStore(state => state.order);
  const { removeDocument, ...rest } = useAssetRemove();
  const [selectedDocument, setSelectedDocument] = useState<IDocument>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onRemoveClick = async (document: IDocument) => {
    try {
      await removeDocument(order?.orderId, document?.id);
      showSuccessToast("successfully remove the document.");
      queryClient.invalidateQueries([`case-id-${order?.orderId}`]);
      onClose();
    } catch (err) {
      showErrorToast("unable to remove document.");
    }
  };

  const onViewClick = (document: IDocument) => {
    setSelectedDocument(document);
    onOpen();
  };

  const handleModalRemove = () => {
    if (selectedDocument) {
      onRemoveClick(selectedDocument);
    } else {
      showErrorToast("No Document is Selected.");
    }
  };

  const handleView = () => {
    window.open(selectedDocument?.url, "_blank");
  };

  const documents = order?.documents?.filter(bthDocumentFilter) || [];
  const isUploadDisable = documents.length >= MAX_DOCUMENT_COUNT;

  return (
    <Wrap w={"full"} bg={darkSlate} py={4} mt={6} spacing={10} borderRadius={"md"}>
      <Box w={"full"} mb={"-6"}>
        <Flex justify={"space-between"} align={"center"} px={4}>
          <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
            BTH Slips
          </Heading>
          <UploadDocumentButton isDisabled={isUploadDisable} description={`BTH SLIP - ${documents?.length + 1}`} tag="BTH_SLIP" title="BTH Slip Upload" onlyUpload />
        </Flex>
        <Divider my={2} />
      </Box>
      {documents?.length <= 0 && (
        <Center w={"full"} pt={5}>
          <Text textAlign={"center"} fontWeight={"semibold"} fontSize={"sm"}>
            🤷 No BTH Document uploaded Yet
          </Text>
        </Center>
      )}
      <Stack w={"full"} gap={4} px={4}>
        {documents?.map((document: IDocument) => (
          <DocumentCard key={document.id} {...document} onClick={() => onViewClick(document)} onRemoveClick={() => onRemoveClick(document)} />
        ))}
      </Stack>
      {documents?.length >= MAX_DOCUMENT_COUNT && (
        <Center w={"full"}>
          <Text textAlign={"center"} fontWeight={"normal"} fontSize={"sm"} color={"red.500"}>
            🤷 You can upload maximum {MAX_DOCUMENT_COUNT} documents.
          </Text>
        </Center>
      )}
      <Drawer isOpen={isOpen} placement="bottom" onClose={() => !rest?.isLoading && onClose()}>
        <DrawerOverlay />
        <DrawerContent minH={"70vh"} bg={"white"}>
          <DrawerCloseButton />
          <DrawerHeader>{selectedDocument?.description}</DrawerHeader>

          <DrawerBody>
            <Center flexDir={"column"} w={"full"}>
              {selectedDocument?.url && <AssetRender url={selectedDocument?.url} />}
              <Divider />
              <Box bg={"gray.100"} w={"full"} px={4} py={2} borderRadius={"md"} my={5}>
                <Flex align={"center"} gap={2}>
                  <Flex justify={"center"}>
                    <Icon icon={"eva:person-outline"} fontSize={20} />
                    <Text fontSize={"sm"}>Upload By: </Text>
                  </Flex>
                  <Text fontSize={"sm"} fontWeight={"semibold"}>
                    {selectedDocument?.uploadedBy}
                  </Text>
                </Flex>

                <Flex align={"center"} gap={2}>
                  <Flex align={"center"} gap={1}>
                    <Icon icon={"lucide:clock-9"} fontSize={16} />
                    <Text fontSize={"sm"}>Created At: </Text>
                  </Flex>
                  <Text fontSize={"sm"} fontWeight={"semibold"}>
                    {convertEpochToDate(selectedDocument?.timestamp)}
                  </Text>
                </Flex>
              </Box>
            </Center>
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={handleModalRemove} borderRadius={"full"} fontSize={"sm"} fontWeight={"semibold"} height={8} isLoading={rest?.isLoading}>
              Remove Document
            </Button>
            <Button colorScheme="blue" borderRadius={"full"} fontSize={"sm"} fontWeight={"semibold"} height={8} onClick={handleView}>
              View Document
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Wrap>
  );
}
