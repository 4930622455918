import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { caseOnHoldModelClose, caseOnHoldModelOpen, toggleOnHoldButton } from "@config/event";
import { useOrder } from "@hooks/api/useSave";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useRedStore } from "@store/store";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function OnHold({ isDisabled, isLiteMode }: { isDisabled?: boolean; isLiteMode?: boolean }) {
  const [isOnHold, setIsOnHold]: any = useState(false);
  const { updateCaseOnHold, ...rest } = useOrder();
  const isCaseOnHold = useRedStore(state => state.order.isCaseOnHold);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: orderId } = useParams();

  const handleModalOpen = () => {
    caseOnHoldModelOpen({
      orderId: orderId,
    });
    onOpen();
  };

  const handleModalClose = () => {
    caseOnHoldModelClose({
      orderId: orderId,
    });
    onClose();
  };

  const handleCancelClick = (e: any) => {
    const state = e.target.checked;
    toggleOnHoldButton({ isOnHold: state });
    setIsOnHold(state);
    updateCaseOnHold({
      isCaseOnHold: state,
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    setIsOnHold(isCaseOnHold);
  }, [isCaseOnHold]);

  if (isLiteMode) {
    return (
      <Flex justify={"space-between"} align={"center"} w={"full"}>
        <Box>
          <Flex align={"center"}>
            <Flex justify={"center"} align={"center"} bg={"red.100"} borderRadius={"full"} mr={2} color={"red.600"} pos={"relative"} h={8} w={8}>
              <Icon icon={"zondicons:pause-outline"} fontSize={16} />
            </Flex>
            <Stack>
              <Heading fontWeight={"semibold"} fontSize={"sm"} textTransform={"capitalize"}>
                Case On Hold
              </Heading>
              <Text fontSize={"xs"} mt={-2}>
                Toggle to remove case holding.
              </Text>
            </Stack>
          </Flex>
        </Box>
        <Box>
          <Switch disabled={rest.isLoading} colorScheme="red" size="lg" isChecked={isOnHold} onChange={handleCancelClick} />
        </Box>
      </Flex>
    );
  }

  return (
    <Box w={"full"}>
      <Button
        onClick={handleModalOpen}
        borderRadius={"md"}
        variant={"solid"}
        fontSize={"sm"}
        size={"sm"}
        w="full"
        fontWeight={"semibold"}
        my={2}
        isDisabled={isDisabled}
        _disabled={{
          bg: "blackAlpha.300",
          opacity: 0.5,
        }}
        leftIcon={<Icon icon={"zondicons:pause-outline"} fontSize={16} />}
      >
        Hold This Case
      </Button>
      <Modal isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent mx={5} bg={"white"}>
          <ModalCloseButton />
          <ModalHeader>Case On Hold</ModalHeader>
          <Divider />
          <ModalBody>
            <Box overflowY={"scroll"} bg={"whiteAlpha.200"} p={2} mx={-2} borderRadius={"md"}>
              <Box m={2}>
                <Flex justify={"space-between"} align={"center"} w={"full"}>
                  <Box>
                    <Flex align={"center"}>
                      <Flex justify={"center"} align={"center"} bg={"primary.100"} borderRadius={"full"} mr={2} color={"primary.600"} pos={"relative"} h={8} w={8}>
                        <Icon icon={"zondicons:pause-outline"} fontSize={16} />
                      </Flex>
                      <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
                        On Hold
                      </Heading>
                    </Flex>
                  </Box>
                  <Box>
                    <Switch disabled={rest.isLoading} colorScheme="teal" size="lg" defaultChecked={isOnHold} onChange={handleCancelClick} />
                  </Box>
                </Flex>
                <Text fontSize={"sm"} mt={5}>
                  The hospital manager doesn't know when the patient can receive services due to unexpected factors like patient condition and resource availability.
                </Text>
              </Box>
            </Box>
          </ModalBody>
          <Divider />
        </ModalContent>
      </Modal>
    </Box>
  );
}

const OnHoldSwitch = ({ isLoading, isOnHold, handleCancelClick }: any) => {
  useEffect(() => {}, [isOnHold, isLoading]);
  return (
    <Flex justify={"space-between"} align={"center"} w={"full"}>
      <Box>
        <Flex align={"center"}>
          <Flex justify={"center"} align={"center"} bg={"primary.100"} borderRadius={"full"} mr={2} color={"primary.600"} pos={"relative"} h={8} w={8}>
            <Icon icon={"zondicons:pause-outline"} fontSize={16} />
          </Flex>
          <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
            On Hold
          </Heading>
        </Flex>
      </Box>
      <Box>
        <Switch disabled={isLoading} colorScheme="teal" size="lg" defaultChecked={isOnHold} onChange={handleCancelClick} />
      </Box>
    </Flex>
  );
};
