import React, { useState, useEffect } from "react";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

interface CustomMaskedInputProps {
  name: string;
  defaultValue?: string;
  placeholder?: string;
  leftAddonContent?: string;
}

const CustomMaskedInput: React.FC<CustomMaskedInputProps> = ({ name, defaultValue = "", placeholder, leftAddonContent }) => {
  const { register, watch, setValue } = useFormContext();
  const watchedValue = watch(name);
  const [isUserInput, setIsUserInput] = useState(false);

  // Apply mask to the default value
  const maskValue = (value: string) => {
    if (value.length > 3) {
      return `${value.charAt(0)}${value.slice(1, -2).replace(/\d/g, "*")}${value.slice(-2)}`;
    }
    return value;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUserInput(true); // Mark as user input to avoid masking
    setValue(name, e.target.value, { shouldValidate: true });
  };

  // Determine the value to display
  const displayValue = () => {
    if (isUserInput || !defaultValue) {
      return watchedValue; // Show user input as is
    } else {
      // Apply mask to default value if no user input and default is provided
      return maskValue(defaultValue);
    }
  };

  useEffect(() => {
    // If the component is initialized with a default value and no user input has occurred,
    // update the form value with the masked default value
    if (defaultValue && !watchedValue && !isUserInput) {
      setValue(name, maskValue(defaultValue), { shouldValidate: true });
    }
  }, [defaultValue, isUserInput, name, setValue, watchedValue]);

  return (
    <InputGroup>
      {leftAddonContent && <InputLeftAddon children={leftAddonContent} />}
      <Input maxLength={10} {...register(name)} value={displayValue()} onChange={handleInputChange} placeholder={placeholder} />
    </InputGroup>
  );
};

export default CustomMaskedInput;
