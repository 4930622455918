import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  Wrap,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Icon } from "@iconify/react";

import { useState } from "react";

import { IDocument } from "@config/types";
import { camelCaseToSpaceSeparated, convertEpochToDate, otherDocumentFilter, showErrorToast, showSuccessToast, transformAndFilterObject } from "@utils";
import UploadDocumentButton from "@components/molecules/UploadDocumentButton";
import { useRedStore } from "@store/store";
import DocumentCard from "@components/molecules/DocumentCard";
import { useAssetRemove } from "@hooks/api/useCaseApi";
import { queryClient } from "scripts/reactQuery";
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from "@chakra-ui/react";
import AssetRender from "@components/molecules/AssetRender";
import { MAX_DOCUMENT_COUNT } from "@config/constant";
import TextRenderWithIcon from "@components/atom/TextRenderWithIcon";
import { getProperty } from "dot-prop";
import Rating from "@components/molecules/Rating";

export default function Feedback() {
  const darkSlate = useColorModeValue("white", "slate.800");
  const order = useRedStore(state => state.order);

  const extractValue = (key: string, defaultVal?: string) => (order && getProperty(order, `feedback.${key}`, defaultVal)) || "";

  const nonConsiderKey: any = ["rating", "date", "testimonial", "comments"];

  const linearArr = order.feedback && transformAndFilterObject(order.feedback, nonConsiderKey);

  return (
    <Wrap w={"full"} bg={darkSlate} py={4} pb={0} mt={6} spacing={10} borderRadius={"md"}>
      <Box w={"full"} mb={"-6"}>
        <Flex justify={"space-between"} align={"center"} px={4}>
          <Heading fontWeight={"semibold"} fontSize={"small"} color={"gray.700"}>
            Feedback
          </Heading>
        </Flex>
        <Divider my={2} />
      </Box>
      <Stack gap={6} px={4}>
        {extractValue("date") && <TextRenderWithIcon icon="icon-park-twotone:time" title="Submitted At" description={convertEpochToDate(parseInt(extractValue("date")), "DD MMM YYYY HH:mm a")} />}
        <SimpleGrid columns={2} w={"full"} gap={4}>
          <TextRenderWithIcon icon="dashicons:testimonial" title="Testimonial" description={extractValue("testimonial")} isStrictMode />
          <TextRenderWithIcon icon="majesticons:comments" title="Comments" description={extractValue("comments")} isStrictMode />
        </SimpleGrid>
        {!isNaN(parseInt(extractValue("rating"))) && (
          <Box mt={-2}>
            <Rating score={parseInt(extractValue("rating"))} showLabel />
          </Box>
        )}
      </Stack>
      <Stack w={"full"} borderRadius={"md"} borderTopRadius={0} overflow={"hidden"} borderWidth={1} borderTop={0} borderBottom={0}>
        <Accordion borderRadius={"sm"} allowToggle>
          {linearArr?.map((itm: any) => (
            <FeedBackItem itm={itm} key={itm?.key} />
          ))}
        </Accordion>
      </Stack>
    </Wrap>
  );
}

const FeedBackItem = ({ itm }: { itm: { key: string; value: [] } }) => {
  return (
    <AccordionItem borderColor={"gray.200"}>
      <h2>
        <AccordionButton _expanded={{ bg: "gray.300", color: "black" }} bg={"gray.50"}>
          <Box as="span" flex="1" textAlign="left" fontSize={"sm"} fontWeight={"semibold"} color={"blackAlpha.800"} textTransform={"capitalize"}>
            {camelCaseToSpaceSeparated(itm.key)}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} bg={"white"}>
        <Flex wrap={"wrap"} gap={2}>
          {itm.value?.map(item => (
            <Tag key={item} colorScheme="red" size={"md"}>
              {item}
            </Tag>
          ))}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
};
