import { Badge, Box, Card, Center, Divider, Flex, IconButton, Image, Stack, Text } from "@chakra-ui/react";
import { IDocument } from "@config/types";
import { useAssetRemove } from "@hooks/api/useCaseApi";
import { Icon } from "@iconify/react";
import { convertEpochToDate } from "@utils";
import { timeStamp } from "console";
import React from "react";

// const {useAssetRemove} = useAssetRemove()

// const removeAsset = () => {
//   useAssetRemove()
// };

interface IDocumentCard extends IDocument {
  showRemoveButton?: boolean;
  onClick: () => void;
  onRemoveClick: () => void;
}

export default function DocumentCard(props: IDocumentCard) {
  const { description, id, tag, timestamp: tmp, uploadedBy, url, onClick, onRemoveClick, showRemoveButton } = props;

  const removeAsset = () => {
    event?.preventDefault();
    onRemoveClick();
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <Card borderWidth={0} shadow={"none"} borderColor={"gray.300"} borderRadius={"md"} onClick={handleClick} px={0}>
      <Flex w={"full"} justify={"space-between"} align={"start"}>
        <Flex align={"start"}>
          <Box h={12} w={12} minW={12} borderRadius={"md"} borderWidth={1} overflow={"hidden"} mr={2}>
            <RenderImage url={url} />
          </Box>
          <Stack align={"start"} gap={1}>
            {/* <Text fontSize={"sm"} fontWeight={"normal"}>
              {description}
            </Text> */}
            <Badge colorScheme="orange" fontSize={12}>
              {tag?.toString().replace(/_/g, " ")}
            </Badge>
            <Text fontSize={"sm"} fontWeight={"normal"}>
              {convertEpochToDate(tmp || 0, "DD MMM YYYY h:MM a")}
            </Text>
          </Stack>
        </Flex>
        <Flex h={10} w={10} align={"center"} justify={"center"} borderRadius={"md"} minW={10} ml={2}>
          {showRemoveButton && <Icon color="black" icon={"flowbite:trash-bin-outline"} onClick={removeAsset} fontSize={20} />}
        </Flex>
      </Flex>
      {/* <Divider my={2} />
      <Flex px={2} pb={2}>
        <Text fontSize={"xs"}>Upload By: {uploadedBy} </Text>
      </Flex> */}
    </Card>
  );
}

const RenderImage = ({ url }: { url: string }) => {
  const isImage = [".png", ".jpg", ".jpeg"].some(itm => url?.includes(itm));
  return isImage ? (
    <Image src={url} alt="uploaded" bg={"gray.50"} h={12} w={12} objectFit={"cover"} p={1} borderRadius={"md"} overflow={"hidden"} />
  ) : (
    <Center h={"full"}>
      <Icon icon={"basil:document-outline"} fontSize={24} />
    </Center>
  );
};
