import { IAuditResponse } from "@config/types";
import React, { lazy } from "react";
import {
  Image,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Input,
  Stack,
  Text,
  Flex,
  Box,
  Card,
  Circle,
  Badge,
  useColorModeValue,
  chakra,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { retry } from "utils/retyImport";
import { Icon } from "@iconify/react";

const ShowFullImage = lazy(() => retry(() => import("@components/atom/ShowFullImage")));

interface IAuditInterface {
  label: string;
  value: any;
  index?: number;
}

interface IDocItem {
  category: string;
  url: string;
}

export default function ViewAuditData({ auditData }: { auditData: IAuditInterface[] }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        variant={"ghost"}
        colorScheme="primary"
        size={"xs"}
        borderRadius={"full"}
        fontWeight={"semibold"}
        px={4}
        pr={0}
        mr={0}
        leftIcon={<Icon fontSize={16} icon="material-symbols:ambulance" />}
      >
        View Audit
      </Button>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg={"white"}>
          <DrawerCloseButton />
          <DrawerHeader>Vehicle Audit Data</DrawerHeader>
          <Divider />
          <DrawerBody>
            <Stack>
              {auditData?.map((itm, index) =>
                Array.isArray(itm.value) ? <RenderFileList key={itm.label} index={index + 1} label={itm.label} fileList={itm.value} /> : <RenderKeyValue key={itm.label} {...itm} index={index + 1} />
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

const RenderKeyValue = (prop: IAuditInterface) => {
  return (
    <Stack mb={10}>
      <Flex gap={1} fontSize={"md"}>
        <Text fontWeight={"semibold"}>{prop.index}.</Text>
        <Text fontWeight={"semibold"}>{prop?.label}</Text>
      </Flex>
      <Flex gap={1}>
        <Text fontWeight={"semibold"}>👉</Text>
        <Text fontSize={"md"}>{prop.value}</Text>
      </Flex>
    </Stack>
  );
};

const RenderFileList = ({ label, index, fileList }: { label: string; index: number; fileList: IDocItem[] }) => {
  return (
    <Stack mb={10}>
      <Flex gap={1} fontSize={"md"}>
        <Text fontWeight={"semibold"}>{index}.</Text>
        <Text fontWeight={"semibold"}>{label}</Text>
      </Flex>
      <Stack gap={10}>
        {fileList?.map((itm, key) => (
          <Box key={itm?.category}>
            <Text fontWeight={"semibold"} fontSize={"md"}>
              {key + 1}.{itm?.category}
            </Text>
            <ProductAddToCart {...itm} />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

function ProductAddToCart({ category, url }: IDocItem) {
  return (
    <Flex p={2} w="full" alignItems="center" justifyContent="center">
      <Flex bg={useColorModeValue("white", "gray.800")} maxW="sm" borderWidth="1px" rounded="lg" shadow="lg" position="relative" overflow={"hidden"} minH={200} w={"full"} justify={"center"}>
        {url && <ShowFullImage url={url} alt={`Picture of ${category}`} />}

        {/* <Box p="6" pt={4} pb={2} pl={4} bgGradient="linear(to-b, transparent, gray.800)" color={"white"} pos={"absolute"} bottom={0} w={"full"}>
          <Flex mt="1" justifyContent="space-between" alignContent="center">
            <Box fontSize="xl" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
              {category}
            </Box>
          </Flex>
        </Box> */}
      </Flex>
    </Flex>
  );
}
