import { Image } from "@chakra-ui/react";
import React from "react";

import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

type Props = {
  url: string;
  alt?: string;
};

export default function ShowFullImage({ url, alt }: Props) {
  return (
    <PhotoProvider>
      <PhotoView src={url}>
        <Image src={url} alt={`Picture of ${alt}`} roundedTop="lg" objectFit={"cover"} />
      </PhotoView>
    </PhotoProvider>
  );
}
