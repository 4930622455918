import { Button, Divider, FormControl, FormLabel, Input, Stack, useDisclosure } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import Select from "react-select";
import { MAX_DOCUMENT_COUNT, TAG_MAP } from "@config/constant";
import { useEffect, useState } from "react";
import { useRedStore } from "@store/store";
import { useAssetUpload } from "@hooks/api/useCaseApi";
import { otherDocumentFilter, showErrorToast, showSuccessToast } from "@utils";
import { queryClient } from "scripts/reactQuery";
import { UploadAsset } from "@components/molecules/UploadAsset";

interface IUploadDocumentButton {
  isDisabled: boolean;
  description?: string;
  tag?: string;
  title?: string;
  onlyUpload?: boolean;
}

export default function UploadDocumentButton({ isDisabled, description, tag, title, onlyUpload }: IUploadDocumentButton) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [assetState, setAssetState] = useState({ description: description || "", tag: tag || "", url: "" });
  const order = useRedStore(state => state.order);
  const { uploadDocument, ...rest } = useAssetUpload();

  const onTagSelect = (e: any) => {
    if (e?.value) {
      setAssetState(prev => ({ ...prev, tag: e?.value }));
    }
  };

  const handleClose = () => {
    setAssetState({ description: "", tag: "", url: "" });
    onClose();
  };

  const onImageUpload = (payload: any) => {
    setAssetState(prev => ({ ...prev, url: payload?.url }));
  };

  const handleAssetUpload = async () => {
    try {
      const payload = {
        orderId: order.orderId,
        tag: assetState.tag,
        url: assetState.url,
        description: assetState?.description,
      };
      const _payload = {
        ...payload,
        description: assetState?.description,
      };
      await uploadDocument(_payload);
      showSuccessToast("Document upload successfully.");
      queryClient.invalidateQueries([`case-id-${order.orderId}`]);
      handleClose();
    } catch (err) {
      showErrorToast("Unable to save uploaded image.");
      return;
    }
  };

  useEffect(() => {
    if (description && tag) {
      setAssetState(prev => ({ ...prev, description, tag }));
    }
  }, [description, tag]);

  return (
    <>
      <Button
        variant={"ghost"}
        colorScheme="primary"
        size={"xs"}
        borderRadius={"full"}
        fontWeight={"semibold"}
        px={4}
        pr={0}
        mr={0}
        leftIcon={<Icon fontSize={20} icon="line-md:upload-loop" />}
        onClick={onOpen}
        isDisabled={isDisabled}
        _focus={{ borderWidth: 0 }}
      >
        Upload
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent mx={4} bg={"white"}>
          <ModalHeader>{title ? title : "Asset Upload"}</ModalHeader>
          <Divider />
          <ModalBody>
            <form>
              <Stack gap={5}>
                {/* <FormControl display={"none"}>
                  <FormLabel>Description</FormLabel>
                  <Input h={9} type="text" onChange={e => setAssetState(prev => ({ ...prev, description: e.target.value }))} />
                </FormControl> */}
                <FormControl isRequired display={onlyUpload ? "none" : "block"}>
                  <FormLabel>Tag</FormLabel>
                  <Select
                    options={TAG_MAP}
                    // value={options.find(itm => itm.value === value)}
                    placeholder={"Tag"}
                    onChange={onTagSelect}
                    isClearable={false}
                    styles={{
                      control: (baseStyles: any) => ({
                        ...baseStyles,
                        fontSize: 14,
                        fontWeight: 500,
                        minWidth: 200,
                        paddingLeft: 8,
                        maxWidth: "full",
                        minHeight: "35px",
                        height: "35px",
                      }),
                      valueContainer: provided => ({
                        ...provided,
                        height: "35px",
                        padding: "0 6px",
                      }),
                      input: provided => ({
                        ...provided,
                        margin: "0px",
                      }),
                      indicatorSeparator: () => ({
                        display: "none",
                      }),
                      indicatorsContainer: provided => ({
                        ...provided,
                        height: "30px",
                      }),
                      option: (base: any) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 14,
                        fontWeight: 500,
                        letterSpacing: "0.02em",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                        color: "black",
                      }),
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Files</FormLabel>
                  <UploadAsset isDisabled={!assetState?.tag} onSuccess={onImageUpload} orderId={order.orderId} tag={assetState.tag} />
                </FormControl>
              </Stack>
            </form>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button isDisabled={rest?.isLoading} variant="ghost" mr={3} fontWeight={"semibold"} onClick={handleClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleAssetUpload}
              size={"sm"}
              borderRadius={"full"}
              fontWeight={"semibold"}
              px={6}
              isDisabled={Object.values(assetState)?.some(itm => !itm)}
              isLoading={rest?.isLoading}
            >
              Save the Documents
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
