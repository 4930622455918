import { Box, Flex, Image } from "@chakra-ui/react";
import ShowFullImage from "@components/atom/ShowFullImage";
import { getIsAllowAssetType, getIsImageType } from "@utils";
import React, { useEffect, useState } from "react";

type Props = {
  url: string;
};

export default function CustomPDFViewer({ url }: Props) {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    setPdfUrl(url);
  }, [url]);

  const isImage = getIsImageType(url);
  const isPdf = getIsAllowAssetType(url);

  return (
    <Flex minH={"200px"} w={"90vw"} justify={"center"} align={"center"} borderRadius={"md"} mb={5} shadow={"md"} borderWidth={1}>
      {pdfUrl && isPdf && (
        <Box style={{ position: "relative" }}>
          <Box w={"90vw"}>
            <iframe className="holds-the-iframe" src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`} title="PDF Viewer" width="100%" height="600px" />
          </Box>
        </Box>
      )}
      {isImage && <ShowFullImage url={url} />}
    </Flex>
  );
}
