import { Box, Flex, Image, Stack, Tag, Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  score: number;
  showLabel?: boolean;
};

const MAPPER: any = {
  0: "/assets/reaction/bad.svg",
  1: "/assets/reaction/bad.svg",
  2: "/assets/reaction/bad.svg",
  3: "/assets/reaction/bad.svg",
  4: "/assets/reaction/bad.svg",
  5: "/assets/reaction/sad.svg",
  6: "/assets/reaction/sad.svg",
  7: "/assets/reaction/normal.svg",
  8: "/assets/reaction/normal.svg",
  9: "/assets/reaction/ok.svg",
  10: "/assets/reaction/happy.svg",
};

const COLOR_SCHEMA: any = {
  0: "red",
  1: "red",
  2: "red",
  3: "red",
  4: "red",
  5: "orange",
  6: "orange",
  7: "gray",
  8: "gray",
  9: "green",
  10: "green",
};

export default function Rating({ score, showLabel }: Props) {
  if (showLabel) {
    return (
      <Box>
        <Flex justify={"start"} align={"start"} py={2} gap={2}>
          <Image w={8} src={MAPPER[score]} p={1.5} bg={"purple.50"} borderRadius={"full"} />
          <Stack>
            <Text fontSize={"xs"} fontWeight={"normal"} color="blackAlpha.700" textTransform={"capitalize"}>
              Rating
            </Text>
            <Tag variant={"subtle"} colorScheme={COLOR_SCHEMA[score]} fontWeight={"semibold"} borderRadius={"full"}>
              {score} /10
            </Tag>
          </Stack>
        </Flex>
      </Box>
    );
  }
  return (
    <Flex justify={"space-between"} align={"center"} px={4} py={2}>
      <Box>
        <Flex align={"center"} gap={2}>
          <Image h={5} src={MAPPER[score]} />
          <Tag variant={"subtle"} colorScheme={COLOR_SCHEMA[score]} fontWeight={"semibold"} borderRadius={"full"}>
            {score} /10
          </Tag>
        </Flex>
      </Box>
    </Flex>
  );
}
